import {handleActions} from "redux-actions";
import {FallAlarmTypes,DockAlarmTypes,SystemAlarmTypes,AccgetidTypes,RealtimebillTypes,CallInManageTypes,MobileAreaTypes,HistoryConTypes,GlobalvariTypes,DeviceManTypes,DeviceUsageHisTypes,CodeNumManTypes} from "../../actions/alarmset/alarmsetTypes";

const defaultState={
    fallalarmList:[],
    fallalarmtotal:"",

    dockalarmList:[],
    dockalarmtotal:"",

    systemalarmList:[],
    systemalarmtotal:"",
    
    AccgetidStateList:[],
    AccgetidStatetotal:"",

    RealtimebillList:[],
    Realtimebilltotal:"",

    CallInManageList:[],
    CallInManagetotal:"",

    MobileAreaList:[],
    MobileAreatotal:"",

    HistoryConList:[],
    HistoryContotal:"",

    GlobalvariList:[],
    Globalvaritotal:"",

    DeviceManList:[],
    DeviceMantotal:"",

    DeviceUsageHisList:[],
    DeviceUsageHistotal:"",

    CodeNumManList:[],
    CodeNumMantotal:""
}

export default handleActions({
    [FallAlarmTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let FallAlarmState=JSON.parse(JSON.stringify(state));
        FallAlarmState.fallalarmList=action.payload.data;
        FallAlarmState.fallalarmtotal=action.payload.total;
        // console.log(FallAlarmState.fallalarmList,FallAlarmState.fallalarmtotal,101)
        return FallAlarmState
    },
    [DockAlarmTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let DockAlarmState=JSON.parse(JSON.stringify(state));
        DockAlarmState.dockalarmList=action.payload.data;
        DockAlarmState.dockalarmtotal=action.payload.total;
        return DockAlarmState
    },
    [SystemAlarmTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let SystemAlarmState=JSON.parse(JSON.stringify(state));
        SystemAlarmState.systemalarmList=action.payload.data;
        SystemAlarmState.systemalarmtotal=action.payload.total;
        return SystemAlarmState
    },
    [AccgetidTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let AccgetidState=JSON.parse(JSON.stringify(state));
        AccgetidState.AccgetidStateList=action.payload.data;
        AccgetidState.AccgetidStatetotal=action.payload.total;
        return AccgetidState
    },
    [RealtimebillTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let RealtimebillState=JSON.parse(JSON.stringify(state));
        RealtimebillState.RealtimebillList=action.payload.data;
        RealtimebillState.Realtimebilltotal=action.payload.total;
        return RealtimebillState
    },
    [CallInManageTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let CallInManageState=JSON.parse(JSON.stringify(state));
        CallInManageState.CallInManageList=action.payload.data;
        CallInManageState.CallInManagetotal=action.payload.total;
        return CallInManageState
    },
    [MobileAreaTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let MobileAreaState=JSON.parse(JSON.stringify(state));
        MobileAreaState.MobileAreaList=action.payload.data;
        MobileAreaState.MobileAreatotal=action.payload.total;
        return MobileAreaState
    },
    [HistoryConTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let HistoryConState=JSON.parse(JSON.stringify(state));
        HistoryConState.HistoryConList=action.payload.data;
        HistoryConState.HistoryContotal=action.payload.total;
        return HistoryConState
    },
    [GlobalvariTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let GlobalvariState=JSON.parse(JSON.stringify(state));
        GlobalvariState.GlobalvariList=action.payload.data;
        GlobalvariState.Globalvaritotal=action.payload.total;
        return GlobalvariState
    },
    [DeviceManTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let DeviceManState=JSON.parse(JSON.stringify(state));
        DeviceManState.DeviceManList=action.payload.data;
        DeviceManState.DeviceMantotal=action.payload.total;
        return DeviceManState
    },
    [DeviceUsageHisTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let DeviceUsageHisState=JSON.parse(JSON.stringify(state));
        DeviceUsageHisState.DeviceUsageHisList=action.payload.data;
        DeviceUsageHisState.DeviceUsageHistotal=action.payload.total;
        return DeviceUsageHisState
    },
    [CodeNumManTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let CodeNumManState=JSON.parse(JSON.stringify(state));
        CodeNumManState.CodeNumManList=action.payload.data;
        CodeNumManState.CodeNumMantotal=action.payload.total;
        return CodeNumManState
    },
},defaultState)