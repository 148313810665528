import { Layout } from '../layout';
import { getMessage, submit } from './api';
import { Button, Tag, Table } from 'antd';
import React, { memo, useReducer, useMemo } from 'react';
// 初始化的一些数据
const initialState = {
    repload: false,
    // 查询字段
    query: {
        id: '',
        page: 1,
        is_read: '',
        query: 'all',
        time_type: '',
        page_size: 10,
    },
    // 表格数据
    table_data: {
        data: [],
        total: 0,
        current: 0,
        loading: false
    }
}
// 监听派发事件进行数据处理
const reducer = (state, action) => {
    switch (action.type) {
        case "RESET":
            return {
                ...initialState,
                repload: !state.repload
            }
        case "UPDATE":
            return {
                ...state,
                ...action.data,
            }
        default:
            return state;
    }
}
const day = [3, 7];
const level = [<Tag >次要</Tag>, <Tag color='green'>一般</Tag>, <Tag color='orange'>主要</Tag>, <Tag color="red">紧急</Tag>];
const Message = props => {
    const columns = [
        {
            width: 100,
            title: '序号',
            fixed: 'left',
            align: 'center',
            dataIndex: 'key',
            render: (text, record, index) => <a>{index + 1}</a>
        },
        {
            align: 'center',
            title: '对象名称',
            dataIndex: 'object_name',
        },
        {
            title: '告警类型',
            align: 'center',
            dataIndex: 'alarm_type',
        },

        {
            align: 'center',
            title: '告警级别',
            dataIndex: 'alarm_rank',
            render: text => level[text]
        },
        {
            align: 'center',
            title: '阀值',
            dataIndex: 'threshold',
        },


        {
            align: 'center',
            title: '告警信息说明',
            dataIndex: 'alarm_message',
        },
        {
            align: 'center',
            title: '设置时间',
            dataIndex: 'release_time',
        },

        {
            align: 'center',
            title: '告警信息',
            dataIndex: 'message',
        },
        {
            align: 'center',
            title: '是否确认',
            dataIndex: 'is_read',
            render: (text, record, index) => text === '0' ? <Button onClick={() => onSubmit(record)} type='primary'>未确认</Button> : <Button disabled>已确认</Button>
        },
        {
            align: 'center',
            title: '确认人',
            dataIndex: 'username',
        },
        {
            align: 'center',
            title: '告警时间',
            dataIndex: 'alarm_time',
        },

    ];
    const [state, dispatch] = useReducer(reducer, initialState);
    const { page, page_size, is_read, time_type } = state.query;
    const { loading, total, current } = state.table_data;
    // 填充数据
    const setData = (data) => dispatch({ type: 'UPDATE', data: { ...state, ...data } });
    const onSubmit = async item => {
        const res = await submit({ id: item.id, user_id: window.user_id });
        if (res) setData({ repload: !state.repload });
    }
    // 刷新全部并重置
    const onReload = () => dispatch({ type: 'RESET' });
    // 点击分页
    const onChange = (page, page_size) => setData({ table_data: { ...state.table_data, current: page }, query: { ...state.query, page_size, page, } });
    useMemo(() => {
        const getData = async () => {
            setData({ table_data: { loading: true } });
            const res = await getMessage(state.query);
            if (res && res.data) setData({ table_data: { data: res.data, total: res.total, current, loading: false } });
        }
        console.log();
        getData();
        return () => null;
    }, [page, state.repload, is_read, time_type]);

    return (
        <Layout style={{ margin: '20px' }}>
            <div className='AumTop'>
                <div className='leftTop'>
                    <Button onClick={onReload}> 刷新 </Button>
                    <div style={{ display: 'inline-block' }}>
                        {day.map(item => <Button key={item} onClick={() => setData({ query: { ...state.query, time_type: item, page: 0, }, table_data: { current: 0 } })}>近{item}天</Button>)}
                    </div>
                </div>
                <div className='rightTop' style={{ width: 'auto' }}>
                    <Button type='primary' onClick={() => setData({ query: { ...state.query, is_read: '0', query: '', page: 0 }, table_data: { current: 0 } })} style={{ marginRight: '10%' }}> 查看所有未确认消息 </Button>
                </div>

            </div>
            <div>
                <Table
                    rowKey={'id'}
                    columns={columns}
                    loading={loading}
                    scroll={{ x: true }}
                    pagination={{
                        total,
                        current,
                        onChange,
                        pageSize: page_size,
                        showTotal: (total, range) => `一共 ${total} 条 显示 ${range[0]} 条到 ${range[1]} 条`,
                    }}
                    dataSource={state.table_data.data}
                />

            </div>
        </Layout>
    )
}

export default memo(Message);