export const customerTypes="CUSTOMER_TYPES";

export const cusModifyTypes="CUS_MODIFY_TYPES";

export const fallLandTypes="FALLLAND_TYPES";

export const fallModifyTypes="FALL_MODIFY_TYPES";

export const cusAddTypes="CUS_ADD_TYPES";

export const fallAddTypes="FALL_ADD_TYPES";

export const hidnumTypes="HID_NUM_TYPES";

export const hidModifyTypes="HID_MODIFY_TYPES";

export const hidAddTypes="HID_ADD_TYPES";

export const pipelineTypes="PIPELINE_TYPES";

export const querypageTypes="QUERY_PAGE_TYPES";

export const recycleTypes="RECYCLE_TYPES";

export const fallrecycleTypes="FALL_RECYCLE_TYPES";

export const hidrecycleTypes="HID_RECYCLE_TYPES";

export const raterecycleTypes="RATE_RECYCLE_TYPES";

export const FallLandrouteTypes="FALL_LAND_ROUTE_TYPES";