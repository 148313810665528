import {createStore,combineReducers,applyMiddleware} from "redux";
import reduxThunk from "redux-thunk";
import user from "./reducers/user";
import customer from "./reducers/customer";
import gatewaybus from "./reducers/gatewaybus";
import monitora from "./reducers/monitora";
import alarmset from "./reducers/alarmset";
import newaccount from "./reducers/newaccount";
import {composeWithDevTools} from "redux-devtools-extension";

const reducers = combineReducers({
    user,
    customer,
    gatewaybus,
    monitora,
    alarmset,
    newaccount
})

const store = createStore(reducers,composeWithDevTools(applyMiddleware(reduxThunk)));

export default store;