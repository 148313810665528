import {handleActions} from "redux-actions";
import {userLoginTypes,rateTypes,keysArrayTypes,changeFlagTypes,seemoreTypes} from "../../actions/usersTypes";
const defaultState = {
    userInfo:JSON.parse(sessionStorage.getItem('userInfo'))||{},
    rateList:[],
    pages:"",
    ratetotal:"",
    flag:0,
    keysArray:[
        {
            key:"/homepage",
            text:"首页"
        }
    ],

    seemoreList:[],
    seemoretotal:"",
}

export default handleActions({
    [changeFlagTypes]:(state,action)=>{
        // console.log(action,5555)
        let changeflagState=JSON.parse(JSON.stringify(state));
        changeflagState.flag=action.payload;
        return changeflagState
    },
    [keysArrayTypes]:(state,action)=>{
        // console.log(state,action,999999)
        let keyaddState=JSON.parse(JSON.stringify(state));
        keyaddState.keysArray=action.payload;
        return keyaddState
    },
    [userLoginTypes]:(state,action)=>{
        // console.log(state,action,99999999)
        let userState = Object.assign({},state);
        userState.userInfo = {account:action.payload.account,username:action.payload.username,password:action.payload.password,_role_name:action.payload._role_name,_role_id:action.payload._role_id,identify:action.payload.identify};
        return userState;
    },
    [rateTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let rateState=JSON.parse(JSON.stringify(state));
        rateState.rateList=action.payload.data;
        rateState.pages=action.payload.pages;
        rateState.ratetotal=action.payload.total;
        return rateState
    },
    [seemoreTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let seemoreState=JSON.parse(JSON.stringify(state));
        seemoreState.seemoreList=action.payload.data;
        seemoreState.seemoretotal=action.payload.total;
        return seemoreState
    },
},defaultState)