import http from "utils/request";
import Cookies from "js-cookie";


let csrf=Cookies.get("csrftoken");
// console.log(csrf,99999999)
// 登陆页面的接口
export const loginApi=(/* account, */username,password,customer,timestamp,seq,digest)=>http.post({
    url:"ajax/v1.0/login/",
    method:"POST",
    data:{/* account, */username,password,customer,timestamp,seq,digest},
    header:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    },
    
})
// 退出页面的接口
export const logoutApi=()=>http.get({
    url:"ajax/v1.0/logout/",
    method:"get",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
}) 

// 修改密码页面的接口
export const changePwdApi=(opassword,password1,password2)=>http.post({
    url:"ajax/v1.0/pwd/",
    method:"POST",
    data:{opassword,password1,password2},
    header:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf,
    },
})
// 获取费率页面的接口
export const rateApi=(page,page_size,feild,sort,name,rate,status)=>http.get({
    url:"ajax/v1.0/tariff_get/",
    method:"get",
    data:{
        page,page_size,feild,sort,name,rate,status
    },
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf,
    },
})

// 费率中修改的接口
export const ModifyRateApi=(name,rate,rate_cycle,/* types */des,status,id)=>http.post({
    url:"ajax/v1.0/tariff_edit/",
    method:"POST",
    supplement:{
        id
    },
    data:{
        name,rate,rate_cycle,/* types, */des,status
    },
    header:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf,
    }
})

// 费率中增加数据
export const addRateApi=(name,rate,rate_cycle,types,des,status)=>http.post({
    url:"ajax/v1.0/tariff_add/",
    method:"POST",
    data:{name,rate,rate_cycle,types,des,status},
    header:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf,
    }  
})

// 费率删除数据
export const delRateApi=(id)=>http.DELETE({
    url:"ajax/v1.0/tariff_edit/",
    method:"DELETE",  
    data:{id},
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf, 
    }
})

// 费率中的查询接口
export const rateQueryApi=(name,rate,status)=>http.get({
    url:"ajax/v1.0/tariff_get/",
    method:"get",
    data:{name,rate,status},
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf, 
    }
})

// 费率中编辑加锁的接口
export const ratelockupApi=(id)=>http.patch({
    url:"ajax/v1.0/tariff_edit/",
    method:"PATCH",
    psupplement:{id},
    header:{
        "content-type":"x-www-form-urlencoded",
        "X-CSRFToken":csrf,
    }
})

// 费率中编辑解锁的接口
export const rateunlockApi=(id)=>http.put({
    url:"ajax/v1.0/tariff_edit/",
    method:"PUT",
    hsupplement:{id},
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf,
    }
})

// 企业账户中的查询接口
export const EnterQueryApi=(acc_name,bind_type,bind_xpool,bind_gateway_group,market,status,payment_type)=>http.get({
    url:"ajax/v1.0/get_customer/",
    method:"get",
    data:{acc_name,bind_type,bind_xpool,bind_gateway_group,market,status,payment_type},
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf, 
    }
})

// 落地（结算）账户中的查询接口
export const FallQueryApi=(acc_name,status)=>http.get({
    url:"ajax/v1.0/get_ground/",
    method:"get",
    data:{acc_name,status},
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf, 
    }
})

// 隐号账户中的查询接口
export const HidQueryApi=(acc_name,status,plat_model)=>http.get({
    url:"ajax/v1.0/get_cain/",
    method:"get",
    data:{acc_name,status,plat_model},
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf, 
    }
})
// 权限列表（可用权限查询）
export const reloadApi=()=>http.get({
    url:"ajax/v1.0/q_per/",
    method:"get",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})
// 角色增加
export const reloadd=(name,Pid,dis)=>http.post({
    url:"ajax/v1.0/role_add/",
    data:{
            role_name:name,
            per_id:Pid,
            des:dis
    },
    method:"POST",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})

//角色获取
export const reloGet=()=>http.get({
    url:"ajax/v1.0/role_get/",
    method:"get",
    data:{
        feild:"create_time",
        sort:1
    },
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})

//获取角色 add_user
export const reloGet1=()=>http.get({
    url:"ajax/v1.0/add_user/",
    method:"get",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})
//角色查看
export const reloSee=(key)=>http.get({
    url:`ajax/v1.0/role_per/${key}`,
    method:"get",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})
//角色删除
export const reloRemove=(key)=>http.get({
    url:`ajax/v1.0/role_del/${key}`,
    method:"get",
    header:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    },
})
//角色编辑
export const reloEedit=(pred,checkId,desValue,status)=>http.post({
    url:"ajax/v1.0/role_edit/",
    data:{
            r_id:pred,
            p_title:checkId,
            des:desValue,
            status:status
    },
    method:"POST",
    header:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    },
})
//角色编辑加锁
    export const reloEeditLock=()=>http.patch({
        url:"ajax/v1.0/role_edit/",
        method:"PATCH",
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
         },
    })
//角色编辑解锁
    export const reloEeditunLock=()=>http.put({
        url:"ajax/v1.0/role_edit/",
        method:"PUT",
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
    })

// 用户获取
export const userGet=(page,page_size,username,role_id,phone,email)=>http.get({
    url:"ajax/v1.0/get_user/",  
    method:"get",
    data:{
        page,
        page_size,
        username,
        role_id,
        phone,
        email,
        feild:"create_time",
        sort:1
    },
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})
// 用户添加
export const userAdd=(name,password,password2,phone,email,des,id)=>http.post({
    url:"ajax/v1.0/add_user/",
    data:{
        username:name,
        password:password,
        password2:password2,
        phone:phone,
        email:email,
        des:des,
        role:id 
    },
    method:"POST",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    },
})
    // 用户删除
    export const userRemove=(keyId)=>http.DELETE({
        url:"ajax/v1.0/edit_user/",  
        method:"DELETE",
        data:{keyId},
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
    //用户编辑
    export const userEdit=(Id,username,password,password2,status,role,phone,email,des)=>http.post({
        url:"ajax/v1.0/edit_user/",  
        method:"POST",
        supplement:{
            Id
        },
        data:{      
                username:username,
                password:password,
                password2:password2,
                status:status,
                role:role,
                phone:phone,
                email:email,
                des:des      
        },
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
    //用户编辑角色
    export const userEditRoleId=(Id)=>http.get({
        url:"ajax/v1.0/edit_user/",  
        method:"get",
        supplement:{
            Id
        },
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
    //用户编辑加锁
    export const userEditLock=(Id)=>http.patch({
        url:"ajax/v1.0/edit_user/",  
        method:"PATCH",
        psupplement:{
            Id
        },
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
    })
    //用户编辑解锁
    export const userEditunLock=(Id)=>http.put({
        url:"ajax/v1.0/edit_user/",  
        method:"PUT",
        hsupplement:{
            Id
        },
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
    })
    //批量删除用户
    export const userAllRemove=(keyId)=>http.DELETE({
        url:"ajax/v1.0/edit_user/",  
        method:"DELETE",
        data:{keyId},
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
    export const SelectFn=(ident)=>http.get({
        url:"ajax/v1.0/q_user/",
        data:{
            username:ident
        },
        method:"get",
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
     // A号码获取
     export const Numget=(page,page_size,groupa,
        affiliation_acc,
        anumber,
        max_limit,
        status,
        des)=>http.get({
        url:"ajax/v1.0/numa_get/",
        method:"get",
        data:{
            page,
            page_size,
            groupa,
            affiliation_acc,
            anumber,
            max_limit,
            status,
            des,
            feild:"create_time",
            sort:1
        },
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
    export const Numget1=()=>http.get({
        url:"ajax/v1.0/numA_get/",
        method:"get",
        data:{
           query:"all"
        },
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
        //A号码增加
        export const Numadd=(anumber,luodi,max_limit,des)=>http.post({
            url:"ajax/v1.0/numA_add/",
            data:{
                anumber:anumber,
                affiliation_acc:luodi,
                max_limit:max_limit,
                des:des  
            },
            method:"POST",
            header:{
                "content-type":"application/json",
                "X-CSRFToken":csrf
            },
        })
        //A号码删除
        export const Numdele=(Id)=>http.DELETE({
            url:"ajax/v1.0/numA_edit/",  
            method:"DELETE",
            data:{Id},
            header:{
                "content-type":"application/json",
                "X-CSRFToken":csrf
            },
        })
        //X池获取所有的组名
        export const AXCallNameGet=()=>http.get({
            url:"ajax/v1.0/numpoolX_get/",  
            method:"get",
            data:{
            query:"all"
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
        //A号码编辑
        export const Numedit=(Id,anumber,max_limit,affiliation,affiliation_acc,status,group,des)=>http.post({
            url:`ajax/v1.0/numA_edit/${Id}`,  
            method:"POST",
            data:{
                anumber:anumber,
                max_limit:max_limit,
                affiliation:affiliation,
                affiliation_acc:affiliation_acc,
                status:status,
                group:group,
                des:des  
            },
            header:{
                "content-type":"application/json",
                "X-CSRFToken":csrf
            },
        })
    //A号码编辑加锁
    export const AEeditLock=(id)=>http.patch({
        url:"ajax/v1.0/numA_edit/",
        method:"PATCH",
        psupplement:{id},
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
    })
    //A号码编辑解锁
    export const AEeditunLock=(id)=>http.put({
        url:"ajax/v1.0/numA_edit/",
        method:"PUT",
        hsupplement:{id},
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
    })
    //AX号码获取
    export const NumXget=()=>http.get({
        url:"ajax/v1.0/numpoolX_get/",  
        method:"get",
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
    })
    //A号码批量上传
    export const Batchupload=(file)=>http.post({
        url:"ajax/v1.0/Anumberupload/",  
                    method:"POST",
                        body: file,
                        headers:{
                            "Content-Type": "multipart/form-data",
                            "X-CSRFToken":csrf
                        } 
    })
    //A号码上传后查看进度
    export const seePlete=(Character_name,kind)=>http.get({
        url:"ajax/v1.0/axplan/",  
                    method:"get",
                    data:{
                        Character_name,
                        kind
                    },
                        headers:{
                            "Content-Type": "multipart/form-data",
                            "X-CSRFToken":csrf
                        } 
    })

        //A池 通过所属账户查所属网关
        export const ACgroundGet=(ground_acc)=>http.get({
            url:"ajax/v1.0/ground/",  
            method:"get",
            data:{
                ground_acc,
                query:"all",
                status:"0"
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
        //A号池获取 通过落地账户获取A号码
        export const getACnum=(group_name,affiliation_acc,page,page_size)=>http.get({
            url:"ajax/v1.0/numA_get/",  
            method:"get",
            data:{
                group_name,
                affiliation_acc,
                page,
                page_size,
                tys:"2",
                group_a:"",
                status:"0"
            },
            headers:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            }
        })
        //A号码池获取
        export const ACnumget=(page,page_size,numpool_name)=>http.get({
            url:"ajax/v1.0/mahao_get/",  
            method:"get",
            data:{
                page,
                page_size,numpool_name
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
        export const ACnumget1=()=>http.get({
            url:"ajax/v1.0/numpoolA_get/",  
            method:"get",
            data:{
            query:"all"
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
     //获取所有A号池的所有组名
     export const ACNameget=()=>http.get({
        url:"ajax/v1.0/numpoolA_get/",  
        method:"get",
        data:{
            query:"all"
        },
        headers:{
            "content-type":"multipart/form-data",
            "X-CSRFToken":csrf
        },
    })
        //A号码池增加
        export const ACnumadd=(num,Max,affiliation,groups,call_types,des)=>http.post({
            url:"ajax/v1.0/numpoolA_add/"+"&status=0&groupa=",  
            method:"POST",
            data:{
                group_name:groups,
                APool:num,
                max_limit:Max,
                affiliation:affiliation,
                call_types,
                des:des
            },
            headers:{
                'content-type':'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            }
        })
     //A号码池删除
        export const ACnumremove=(Id)=>http.DELETE({
            url:"ajax/v1.0/numpoolA_edit/",  
            method:"DELETE",
            data:{Id},
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            }
        })
      //A号码池编辑
       export const ACnumedit=(Id,groups,num,status,status1,Max,affiliation,des)=>http.post({
        url:"ajax/v1.0/numpoolA_edit/",  
        method:"POST",
        supplement:{
            Id
        },
        data:{
            group_name:groups,
            APool:num,
            max_limit:Max,
            affiliation:affiliation,
            status:status,
            call_types:status1,
            des:des
        },
        headers:{
            'content-type':'application/x-www-form-urlencoded',
            "X-CSRFToken":csrf
        }
        }) 
        //A号码编辑加锁
        export const ACEeditLock=(id)=>http.patch({
            url:"ajax/v1.0/numpoolA_edit/",
            method:"PATCH",
            psupplement:{id},
            header:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
        })
        //A号码编辑解锁
        export const ACEeditunLock=(id)=>http.put({
            url:"ajax/v1.0/numpoolA_edit/",
            method:"PUT",
            hsupplement:{id},
            header:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
        })
            //落地网关所对应的号码池 增加
            export const ACnum=(value)=>http.get({
                url:'ajax/v1.0/numA_get/',  
                method:"get",
                data:{
                    query:"all",
                    affiliation_acc:value,
                },
                headers: {
                    "content-type":"application/json",
                    "X-CSRFToken":csrf
                }
            })
            //落地网关获取
            export const groundGet=(e)=>http.get({
                url:"ajax/v1.0/ground/",  
                method:"get",
                data:{
                    ground_acc:e,
                    status:"0"
                },
                headers:{
                    "content-type":"multipart/form-data",
                    "X-CSRFToken":csrf
                },
            })
    //AX号码获取
    export const AXnumGet=(page,page_size,xnumber,max_limit,types,status,batch,areacode,groupx,des,caller_id,xnumber_set)=>http.get({
        url:"ajax/v1.0/numX_get/",  
        method:"get",
        data:{
            page,
            page_size,
            xnumber,
            max_limit,
            types,
            status,
            batch,
            areacode,
            groupx,
            des,
            caller_id,
            xnumber_set,
            feild:"create_time",
            sort:1
        },
        headers:{
            "content-type":"multipart/form-data",
            "X-CSRFToken":csrf
        },
    })

    // x号码导出
    export const xnumberexportapi=(query,xnumber,max_limit,types,status,batch,areacode,groupx,des,caller_id)=>http.get({
        url:"ajax/v1.0/numX_get/",  
        method:"get",
        data:{
            query,
            xnumber,
            max_limit,
            types,
            status,
            batch,
            areacode,
            groupx,
            des,
            caller_id,
            feild:"create_time",
            sort:1
        },
        headers:{
            "content-type":"multipart/form-data",
            "X-CSRFToken":csrf
        },
    })

    // x号码修改全部获取数据
    export const xnumberallbatchmodapi=(query,xnumber,max_limit,types,status,batch,areacode,groupx,des,caller_id,xnumber_set)=>http.get({
        url:"ajax/v1.0/numX_get/",  
        method:"get",
        data:{
            query,
            xnumber,
            max_limit,
            types,
            status,
            batch,
            areacode,
            groupx,
            des,
            caller_id,
            xnumber_set,
            feild:"create_time",
            sort:1
        },
        headers:{
            "content-type":"multipart/form-data",
            "X-CSRFToken":csrf
        },
    })
    
    // x号码池获取数据用做批改
    
    export const XnumxbatchgetApi=(query)=>http.get({
        url:"ajax/v1.0/numpoolX_get/",  
        method:"get",
        data:{
            query,
            feild:"create_time",
            sort:1
        },
        headers:{
            "content-type":"multipart/form-data",
            "X-CSRFToken":csrf
        },
    })

        //AX号码增加
        export const AXnumadd=(xnumber,plat,types,max,des,const1,cain)=>http.post({
            url:"ajax/v1.0/numX_add/",  
            method:"POST",
            data:{
                    xnumber:xnumber,
                    cain_acc:plat,
                    types:types,
                    max_limit:max,
                    cost:const1,
                    cain:cain,
                    des:des
            },
            headers:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
        })
        //AX号码删除
        export const AXnumremove=(Id)=>http.DELETE({
            url:`ajax/v1.0/numX_edit/${Id}`,  
            method:"DELETE",
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            }
        })
        //AX号码编辑
        export const AXnumEdit=(Id,xnumber,cain_acc,cain,types,max,status,group,des,cost,month_money,caller_id)=>http.post({
            url:`ajax/v1.0/numX_edit/${Id}`,  
            method:"POST",
            data:{
                xnumber:xnumber,
                cain_acc:cain_acc,
                cain:cain,
                types:types,
                max_limit:max,
                status:status,
                group:group,
                cost:cost,
                month_money:month_money,
                des:des,
                caller_id:caller_id
            },
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            }
        })
        //X号码编辑加锁
        export const XEeditLock=(id)=>http.patch({
            url:"ajax/v1.0/numX_edit/",
            method:"PATCH",
            psupplement:{id},
            header:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
        })
        //X号码编辑解锁
        export const XEeditunLock=(id)=>http.put({
            url:"ajax/v1.0/numX_edit/",
            method:"PUT",
            hsupplement:{id},
            header:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
       })

        //X池号码获取
        export const AXCnumGet=(page,page_size,group_name,max_limit,status,des)=>http.get({
            url:"ajax/v1.0/numpoolX_get/",  
            method:"get",
            data:{
                page,
                page_size,
                group_name,
                max_limit,
                status,
                des,
                feild:"create_time",
                sort:1
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
        
        export const AXCnumGet1=()=>http.get({
            url:"ajax/v1.0/numpoolX_get/",  
            method:"get",
            data:{
            query:"all"
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
        //X池添加
        export const AXCnumadd=(group_name,XPool,max_limit,des,TypeValue,CallType)=>http.post({
            url:"ajax/v1.0/numpoolX_add/",  
            method:"POST",
            data:{
                    group_name:group_name,
                    XPool:XPool,
                    max_limit:max_limit,
                    des:des,
                    choice_type:TypeValue,
                    call_types:CallType
                },
            headers:{
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            },
        })
        //X池删除
        export const AXCnumremove=(Id)=>http.DELETE({
            url:`ajax/v1.0/numpoolX_edit/${Id}`,  
            method:"DELETE",
            headers: {
                'content-type':'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            }
        })
        //X池号码编辑
        export const AXCnumEdit=(Id,group_name,xpool,status,max,des,TypeValue,call_types)=>http.post({
            url:`ajax/v1.0/numpoolX_edit/${Id}`,  
            method:"POST",
            data:{
                group_name:group_name,
                XPool:xpool,
                max_limit:max,
                status:status,
                des:des,
                choice_type:TypeValue,
                call_types               
            },
            headers:{
                'content-type': 'application/x-www-form-urlencoded',
                "X-CSRFToken":csrf
            }
        })
        //X号池编辑加锁
        export const XCEeditLock=(id)=>http.patch({
            url:"ajax/v1.0/numpoolX_edit/",
            method:"PATCH",
            psupplement:{id},
            header:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
        })
        //X号池编辑解锁
        export const XCEeditunLock=(id)=>http.put({
            url:"ajax/v1.0/numpoolX_edit/",
            method:"PUT",
            hsupplement:{id},
            header:{
                "content-type":"application/x-www-form-urlencoded",
                "X-CSRFToken":csrf
            },
        })
      
        //引号账号获取
        export const GetCain=()=>http.get({
            url:"ajax/v1.0/get_cain/",  
            method:"get",
            data:{
                query:"all",
                status:"0"
            },
            headers:{
                "content-type":"multipart/form-data",
                "X-CSRFToken":csrf
            },
        })
    //平台获取
    export const GetPlat=()=>http.get({
        url:"ajax/v1.0/plat_get/",  
        method:"get",
        headers:{
            "content-type":"multipart/form-data",
            "X-CSRFToken":csrf
        },
    })
    
  //落地网关所对应的号码池
   export const Platnum=(cain_acc,page,page_size)=>http.get({
    url:'ajax/v1.0/numX_get/',  
    method:"get",
    data:{
        cain_acc,
        page,
        page_size,
        tys:"2",
        group_x:"",
        status:"0"
    },
    headers: {
        "content-type":"application/json",
        "X-CSRFToken":csrf
    }
  })
  export const Platnum1=(cain_acc,group_name,page,page_size)=>http.get({
    url:'ajax/v1.0/numX_get/',  
    method:"get",
    data:{
        cain_acc,
        page,
        page_size,
        tys:"2",
        group_x:"",
        group_name,
        status:"0"
    },
    headers:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    }
  })
    //GetCain
    //通用黑名单编辑
    export const BlockListEdit=(data)=>http.post({
        url:"ajax/v1.0/amendblack",  
        method:"POST",
        data,
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })
    //通用黑名单编辑加锁
    export const blackEeditLock=(id)=>http.patch({
        url:"ajax/v1.0/amendblack/",
        method:"PATCH",
        psupplement:{id},
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
    })
    //通用黑名单编辑解锁
    export const blackEeditunLock=(id)=>http.put({
        url:"ajax/v1.0/amendblack/",
        method:"PUT",
        hsupplement:{id},
        header:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        },
  })

    //通用黑名单查询
    export const BlockList=()=>http.get({
        url:"ajax/v1.0/lookblack",  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })
   //隐号网关接口测试展示
   export const Numbertest=(page,page_size,start_time,end_time)=>http.get({
    url:"ajax/v1.0/private_gateway_show_api",  
        method:"get",
        data:{
            page,
            page_size,
            start_time,
            end_time,
            feild:"create_time",
            sort:"1"
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
})
    //隐号网关绑定接口测试AXB/AX
    export const Numbertestadd=(data)=>http.post({
        url:"ajax/v1.0/private_gateway_api_test",  
        method:"POST",
        data,
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })
    //企业账户等级编辑
    export const Leveedit=(id,name,level,des,status)=>http.post({
        url:"ajax/v1.0/level_edit/",  
        method:"POST",
        supplement:{
            id
        },
        data:{
            name:name,
            level:level,
            des:des,
            status:status
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })
    //企业账户等级添加
    export const Leveadd=(name,level,des)=>http.post({
        url:"ajax/v1.0/level_add/",  
        method:"POST",
        data:{
            name:name,
            level:level,
            des:des    
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })

    //企业账户等级删除
    export const Leveremove=(id)=>http.DELETE({
        url:`ajax/v1.0/level_edit/${id}`,  
        method:"DELETE",
        headers:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        }
    })

    //企业帐户等级获取
    export const getConterLeve=(page,page_size)=>http.get({
        url:'ajax/v1.0/level_get/',  
        method:"get",
        data:{
            page,
            page_size,
            feild:"create_time",
            sort:"1"
        },
        headers:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        }
    })

    //A号码批量上传下载文件
    export const download=(name)=>http.get({
        url:"ajax/v1.0/aload/",  
        method:"get",
        data:{
            tablename:name   
        },
        headers:{
            "content-type": "multipart/form-data",
            "X-CSRFToken":csrf
        } 
    })

    //获取企业标识
    export const getCustmer=(page,page_size,acc_name,
        xml_acc_info,
        xml_through_record,xml_bypass_media)=>http.get({
        url:'ajax/v1.0/get_customer/',  
        method:"get",
        data:{
            page,
            page_size,
            acc_name,
            xml_acc_info,
            xml_through_record,
            xml_bypass_media,
            query:"all",
            status:"0",
            type:"1"
        },
        headers:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        }
    })

    //客户参数获取企业标识
    export const getCustmerR=(page,page_size,acc_name,xml_acc_info,xml_through_record,xml_bypass_media,xml_ringback_record)=>http.get({
        url:'ajax/v1.0/get_customer/',  
        method:"get",
        data:{
            page,
            page_size,
            acc_name,
            xml_acc_info,
            xml_through_record,
            xml_bypass_media,
            xml_ringback_record
        },
        headers:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        }
    })

    //获取结算账户
    export const Getgroud=()=>http.get({
        url:"ajax/v1.0/get_ground/",
        method:"get",
        data:{
            query:"all",
            status:"0"
        },
        header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf
        },
     })

//隐号网关获取
export const GetCainway=(e)=>http.get({
    url:"ajax/v1.0/private_gateway_list_all/",  
    method:"get",
    data:{
     user_cain:e,
     query:"all",
     status:"0"   
    },
    headers:{
        "content-type":"multipart/form-data",
        "X-CSRFToken":csrf
    },
 })

//电话清单获取 v1.0/callinfo/
export const getCall=(page,page_size,acc_name,in_call,called_num,virtua_num,start_time,end_time,calltime)=>http.get({
    url:'ajax/v1.0/callinfo/', 
    method:"get",
    data:{
        page,
        page_size,
        acc_name,
        in_call,
        called_num,
        virtua_num,
        start_time,
        end_time,
        calltime,
        feild:"id",
        sort:"1"
    },
    // supplement:{
    //     name,incall,incalled,start,end
    // },
    headers:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    }
})

//随机运营商选号码
     export const getUserCain=(data,signnum)=>http.post({
        url:"ajax/v1.0/random_usercain/",  
        method:"POST",
        data:{
            random:data,
            signnum:signnum
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
    //随机地区选号码
    export const getareaCain=(data,signnum)=>http.post({
        url:"ajax/v1.0/random_area/",  
        method:"POST",
        data:{
            random:data,
            signnum:signnum
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     //获取批次号码
     export const getbatchCain=(data,signnum)=>http.post({
        url:"ajax/v1.0/batch/",  
        method:"POST",
        data:{
            random:data,
            signnum:signnum
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
    //获取所有A号码
     export const getnumAll=()=>http.get({
        url:"ajax/v1.0/numA_get/",  
        method:"get",
        data:{
            query:"all"  
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })
    
     //A号池 add
     export const getnumpoolA_add=()=>http.get({
        url:"ajax/v1.0/numpoolA_add/",  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     //A号池 edit
     export const getnumpoolA_edit=(id)=>http.get({
        url:`ajax/v1.0/numpoolA_edit/${id}`,  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     //获取所有的所有X号码
     export const getnumXAll=()=>http.get({
        url:"ajax/v1.0/numX_get/",  
        method:"get",
        data:{
            query:"all"  
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
    })
     //X号池 add
     export const getnumpoolX_add=()=>http.get({
        url:"ajax/v1.0/numpoolX_add/",  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     //X号池编辑 获取所有已经选过的X号码
     export const numpoolX_edit=(id)=>http.get({
        url:`ajax/v1.0/numpoolX_edit/${id}`,  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
    //获取随机运行商
     export const randomuser=()=>http.get({
        url:"ajax/v1.0/random_usercain/",  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     
     //随机地区选号码
     export const randomarea=()=>http.get({
        url:"ajax/v1.0/random_area/",  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })

     //x号池 edit
     export const getnumpoolX_edit=(id)=>http.get({
        url:`ajax/v1.0/numpoolX_edit/${id}`,  
        method:"get",
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     //取消 清除redis
     export const delDislock1=(xpool)=>http.post({
        url:'ajax/v1.0/delredislock/',  
        method:"POST",
        data:{
            XPool:xpool
        },
        headers:{
            "content-type":"application/x-www-form-urlencoded",
            "X-CSRFToken":csrf
        }
     })
     
   //点击运营商选号   
   export const operatorNum=(xpool)=>http.post({
    url:'ajax/v1.0/click_usercain/',  
    method:"POST",
    data:{
        XPool:xpool
    },
    headers:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    }
   })

 //黑名单查询
 export const blockRem=()=>http.post({
    url:'ajax/v1.0/unifyblack',  
    method:"GET",
    headers:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    }
   })
   //黑名单删除
   export const blockRE=(Bnumber)=>http.get({
    url:'ajax/v1.0/delblack',  
    method:"GET",
    data:{
        Bnumber
    },
    headers:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    }
   })
   //查询出所有企业与对应的企业唯一标识
   export const Getcorpid=()=>http.get({
    url:'ajax/v1.0/corpid',  
    method:"GET",
    data:{
        Corpname:""   
    },
    headers:{
        "content-type":"application/json",
        "X-CSRFToken":csrf
    }
   })
   //个性黑名单获取
   export const GetpersonList=(page,page_size,bnumber,count,CorpID)=>http.post({
    url:`ajax/v1.0/blacklistinquire/?page=${page}&page_size=${page_size}&bnumber=${bnumber}&count=${count}`,  
    method:"POST",
    data:{
        CorpID:CorpID,
        query:"all",
        status:"0"
    },
    headers:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    }
   })
   //个性黑名单查询
   export const GetpersonList1=(page,page_size,bnumber,count,CorpID)=>http.post({
    url:`ajax/v1.0/blacklistinquire/?page=${page}&page_size=${page_size}&bnumber=${bnumber}&count=${count}`,  
    method:"POST",
    data:{
        CorpID:CorpID
    },
    headers:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
   }
   })
   //个性黑名单删除
   export const personRemove=(Bnumber,CorpID)=>http.post({
    url:'ajax/v1.0/selfdomblack',  
    method:"POST",
    data:{
        Bnumber:Bnumber,
        CorpID:CorpID
    },
    headers:{
        "content-type":"application/x-www-form-urlencoded",
        "X-CSRFToken":csrf
    }
   })
//费率查询的时候获取名称的接口
export const ratequerylistApi=(query)=>http.get({
    url:"ajax/v1.0/tariff_get/",
    method:"get",
    data:{
        query
    },
    header:{
            "content-type":"application/json",
            "X-CSRFToken":csrf,
        }
})
//隐号网关获取全部 
export const getCain=(query)=>http.get({
    url:"ajax/v1.0/ground/",
    method:"get",
    data:{
        query
    },
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf,
    }
})
//获取角色菜单
export const getMeau=()=>http.get({
    url:"ajax/v1.0/q_menu/",
    method:"get",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf,
    }
})
//获取批次号码
export const randombatch=()=>http.get({
    url:"ajax/v1.0/batch/",
    method:"get",
    header:{
        "content-type":"application/json",
        "X-CSRFToken":csrf,
    }
})