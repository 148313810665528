export const userLoginTypes="USER_LOGIN_TYPES";

export const userLogoutTypes="USER_LOGOUT_TYPES";

export const changePwdTypes="CHANGE_PWD_TYPES";

export const rateTypes="RATE_TYPES"; 

export const reloadTypes="RELOAD_TYPES";

export const reloadds="RELOADDS";

export const keysArrayTypes="KEYS_ARRAY_TYPES";

export const changeFlagTypes="CHANGE_FLAG_TYPES";

export const seemoreTypes="SEE_MORE_TYPES";