import { menu } from './map';
import Cookies from "js-cookie";
import { Router } from './config';
import { logoutApi } from '../api/user';
import Message from '@/pages/alarm/message';
import { withRouter } from "react-router-dom";
import { submit } from '@/pages/alarm/message/api';
import React, { useState, useEffect, useCallback } from 'react';
import { Menu, Layout, Icon, Tabs, Badge, List, Button, Modal, message } from 'antd';
let $index = 0;
let $wait = false;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Content, Sider } = Layout;
let user = JSON.parse(sessionStorage.getItem('user')) || {};
let _username = user.username;
let _role_name = user._role_name;
let socket = void 0;
const rules = ['/404', '/login'];
const formatTime = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':');
}
const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}
// 根组件渲染
const Root = (props) => {
    const [date, setDate] = useState('');
    const [index, setIndex] = useState(0);
    const [title, setTitle] = useState({});
    const [panes, setPanes] = useState([]);
    const [more, setMore] = useState(false);
    const [config, setConfig] = useState({});
    const [hasOpen, setHasOpen] = useState(false);
    const [activeKey, setActiveKey] = useState('0');
    const [messages, setMessage] = useState(localStorage.getItem('message') && JSON.parse(localStorage.getItem('message')) || []);
    const [count, setCount] = useState(localStorage.getItem('message') && JSON.parse(localStorage.getItem('message')).length || 0);
    const onClick = (e) => {
        if (props.location.pathname === e.item.props.path) return;
        // 路由跳转
        props.history.push(e.item.props.path);
        // 拿到tags key
        let key = panes.length > 0 ? panes[panes.length - 1].key : void 0;
        setTitle({ title: e.key, path: e.item.props.path, closable: true, key: key === void 0 ? 0 : key + 1 });
        const i = getIndex(e.key);
        if (i >= 0) setActiveKey(`${i}`);
    }
    // 头部菜单选择
    const onSelect = (e) => setIndex(e.item.props.index);

    // tags切换
    const onChange = (i) => {
        setActiveKey(i);
        props.history.push(panes[i].path);
    }
    // tags删除
    const onEdit = (targetKey, action) => {
        if (action === 'remove') {
            let key = 0;
            const pane = panes.filter((pane, i) => i !== ~~targetKey);
            if (panes.length > 0) {
                key = pane.length - 1;
            }
            if (pane.length < 1) return;
            setPanes(pane);
            setActiveKey(`${key}`);
            props.history.push(pane[key > 0 ? pane.length - 1 : 0].path);
        }
    };

    // 查询tags是否存在
    const find = title => panes.some(item => item.title === title);

    // 查询当前tags索引
    const getIndex = title => panes.findIndex(item => item.title === title);
    useEffect(() => {
        if (Object.keys(title).length > 0 && !find(title.title)) {
            setPanes([...panes, title]);
            setActiveKey(`${panes.length}`);
        }
        return () => { };
    }, [title]);
    // 退出登陆
    const onLogout = async () => {
        const res = await logoutApi();
        if (res && res.code === 200) {
            Cookies.remove('user');
            props.history.push('/login');
        }
    }

    useEffect(() => {
        let index = 0, ptitle = '';
        const findIndex = (item) => {
            item.children.map((item, i) => {
                if (item.children) {
                    // 拿到归属上级的标题和当前路由的索引
                    item.children.map((v, z) => {
                        if (v.title === window.$route.title) ptitle = item.title; index = z;
                    });
                } else {
                    // 拿到当前路由的索引
                    if (item.title === window.$route.title) index = i;
                }
            });
        }

        if (props.location.pathname !== 'login' && window.$route) {
            if (window.$route.title === '404') {
                setConfig({ pid: `0`, ptitle: '404', index: '0', title: '404' });
                return;
            }
            // 拿到当前页面头部索引
            let pid = menu.findIndex(item => item.title === window.$route.pid);
            // 拿到当前页面头部模块
            let item = menu.find(item => item.title === window.$route.pid);
            // 找寻左侧菜单索引
            findIndex(item);
            const { title, path } = window.$route;
            setIndex(`${pid}`);
            setConfig({ pid: `${pid}`, ptitle, index, title });
            setTitle({ title, path, closable: true, key: 0 });
        }
        // 时间
        const task = () => {
            setDate(formatTime(new Date()));
        };
        task();
        const id = setInterval(task, 1000);
        if (user && user.user_id) {
            window.user_id = user.user_id;
            if (!socket) socket = new WebSocket('wss://zb-sx.cn/v2.0/real_time_message/');
            socket.onopen = () => {
                socket.onmessage = data => {
                    let newmessage = eval("(" + data.data + ")");
                    if (newmessage.code === 200 && newmessage.data) {
                        if (Array.isArray(newmessage.data)) {
                            if (newmessage.data.length > 0) {
                                let old = localStorage.getItem('message') ? JSON.parse(localStorage.getItem('message')) : [];
                                let _messages = [...newmessage.data, ...old];
                                setMessage(_messages)
                                setCount(_messages.length);
                                localStorage.setItem('message', JSON.stringify(_messages));
                                if ($index) return;
                                $index = true;
                                message.success('您有一条新消息', 3, () => $index = false);
                            }

                        }

                    }
                }
                socket.send(`${user.user_id}`);
            }

        }
        return () => {
            clearInterval(id);
            if (socket) socket = void 0;
        }
    }, []);
    const onOpen = useCallback((e) => {
        setHasOpen(!hasOpen);
    }, [setHasOpen, hasOpen]);

    const onConfirm = async item => {
        if ($wait) return;
        $wait = true;
        await submit({ id: item.id, user_id: user.user_id });
        let _message = [...messages].filter(i => i.id !== item.id);
        setMessage(_message);
        setCount(_message.length);
        $wait = false;
        localStorage.setItem('message', JSON.stringify(_message));
    };

    const onMore = useCallback((e) => {
        e.stopPropagation();
        if (hasOpen) setHasOpen(!hasOpen);
        setMore(!more);
    }, [setMore, more, hasOpen, setHasOpen]);

    return (
        <div>
            {!rules.includes(props.location.pathname) && Object.keys(config).length > 0 ? <Layout className="layout">
                {/* 头部菜单 */}
                <div className='header'>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        onClick={onSelect}
                        style={{ lineHeight: '64px' }}
                        defaultSelectedKeys={[config.pid]}
                    >
                        {menu.map((item, index) =>
                            <Menu.Item key={index} index={index}> <Icon type={item.icon} /> {item.title}</Menu.Item>
                        )}
                    </Menu>

                    <Menu theme="dark">
                        <div  >
                            <div style={{ marginRight: '20px', cursor: 'pointer' }}>
                                <Badge showZero count={count} onClick={onOpen} >
                                    <Icon style={{ fontSize: '22px', }} type="bell" />
                                </Badge>
                            </div>
                            {hasOpen &&
                                <div>
                                    <div className='message-mask' onClick={onOpen}> </div>
                                    <List
                                        bordered
                                        className='message'
                                        header={<div>消息通知</div>}
                                        dataSource={messages.sort((a, b) => b.id - a.id)}
                                        footer={<div style={{ textAlign: 'center' }}><Button onClick={onMore}>查看更多</Button></div>}
                                        renderItem={item => (
                                            <List.Item >
                                                <div>{item.message}</div>
                                                <div className='message-action'><p>{item.alarm_time}</p><Button onClick={(e) => { e.stopPropagation(); onConfirm(item) }} type='primary'>确认</Button></div>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            }
                        </div>
                        <div className="nav_right_left">
                            <p style={{ marginBottom: '18px' }}>欢迎您 {`${_role_name}`}/{`${_username}`}</p>
                            <div className="nav_right_left_time">
                                {date}
                            </div>
                        </div>
                        <div className="nav_right_right">
                            <Icon type="user" />
                            <span onClick={onLogout}>退出</span>
                        </div>
                        {/* </NavRight> */}
                    </Menu>
                </div>
                <Layout>
                    {/* 左侧菜单 */}
                    <Sider width={200} style={{ height: '100%' }}>
                        <Menu
                            theme="dark"
                            mode="inline"
                            onClick={onClick}
                            defaultOpenKeys={[config.ptitle]}
                            defaultSelectedKeys={[config.title]}
                        >
                            {menu[index].children && menu[index].children.map((item, i) => {
                                return item.children ? <SubMenu title={<span><Icon type={item.icon} />  <span>{item.title}</span> </span>} key={item.title}>
                                    {item.children.map((u, b) => <Menu.Item key={u.title} path={u.path}> <Icon type={u.icon} />{u.title}</Menu.Item>)}
                                </SubMenu> : <Menu.Item key={item.title} path={item.path}>
                                        <Icon type={item.icon} />
                                        {item.title}
                                    </Menu.Item>
                            })}
                        </Menu>
                    </Sider>
                    {/* 主题内容 */}
                    <Content style={{ padding: '10px' }}>
                        {/* tags部分 */}
                        {panes.length > 0 &&
                            <Tabs
                                hideAdd={true}
                                onEdit={onEdit}
                                type="editable-card"
                                onChange={onChange}
                                activeKey={activeKey}
                            >
                                {panes.map((pane, i) => (
                                    <TabPane path={pane.path} closable={pane.closable} tab={pane.title} key={i} closable={true} />
                                ))}
                            </Tabs>
                        }

                        {/* 动态内容展示 */}
                        <Router style={{ float: 'left', width: '100%' }} />
                    </Content>
                </Layout>

            </Layout> : <Router style={{ width: '100%', height: '100%' }} />}

            <Modal
                footer={null}
                visible={more}
                width={window.innerWidth - 200}
                onCancel={(e) => { onMore(e) }}
            >
                <Message />
            </Modal>
        </div>
    );
}

export default withRouter(Root);
