import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
// import { NoLayoutRoutes } from "router";
// import {RNoLayoutRoutes} from "router/reportform.js";
// import routeEach from "utils/routeEach"
import { HashRouter as Router, Switch } from "react-router-dom"
import store from "./store";
import { Provider } from "react-redux";
ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <ConfigProvider locale={zhCN}>
                    <App />
                </ConfigProvider>
            </Switch>
        </Router>
    </Provider>, document.getElementById('root'));


