/**
 * @author 孟繁波
 * @param path       跳转路由地址
 * @param title      当前导航标题
 * @param children   二级导航 三级导航可有可无  
 * @description      2020-12-05 顶部导航栏和左侧导航栏
 */

import {
    Home,
    Login,
    CodeNumOldNew,
    Role,
    CreateUser,
    Enterprise,
    Fallland,
    FallGate,
    DockingGate,
    NumberA,
    NumberA1,
    HomePage,
    MonitorA,
    Bottom10Region,
    Top10Region,
    LineCodeNum,
    CodeNumDistributionChina,
    Botton20User,
    Top20User,
    CodeNumAssignment,
    MobilePhoneArea,
    DeviceManagement,
    RouteInformation,
    SystemParams,
    BusinessType,
    CallSpeed,
    Error,
    Day,
    Message,
    Line,
    Account
} from "pages";
const menu = [{
    icon: 'home',
    title: '首页',
    children: [{
        path: '/',
        icon: 'copy',
        title: '首页',
    }]
}, {
    title: '报表',
    icon: 'line-chart',
    children: [{
            icon: "underline",
            title: '线路码号分配',
            path: '/reportallpage/linecodenum',
        },
        {
            icon: "underline",
            title: '码号全国分布',
            path: '/reportallpage/codenumdistributionchina',
        },
        {
            icon: "underline",
            title: '码号新旧详情',
            path: '/reportallpage/codenumoldnew',
        },
        {
            icon: "underline",
            title: 'Bottom10地区',
            path: '/reportallpage/bottom10region',
        },
        {
            icon: "underline",
            title: 'TOP10地区',
            path: '/reportallpage/top10region',
        },
        {
            icon: "underline",
            title: 'botton20用户',
            path: '/reportallpage/botton20user',
        },
        {
            icon: "underline",
            title: 'top20用户',
            path: '/reportallpage/top20user',
        },
        {
            icon: "underline",
            title: '码号分配详情',
            path: '/reportallpage/codenumassignment',
        },
    ]
}, {
    title: '账户',
    icon: 'account-book',
    children: [{
        icon: 'home',
        title: '账户管理',
        children: [{
                title: '企业账户',
                icon: 'account-book',
                path: '/usermanagement/enterprise',
            },
            {
                icon: 'fall',
                title: '结算账户',
                path: '/usermanagement/fallland',
            }
        ]
    }]
}, {
    title: '业务',
    icon: 'deployment-unit',
    children: [{
            icon: 'gateway',
            title: '网关业务',
            children: [{
                    icon: 'api',
                    title: '对接网关',
                    path: '/gatewaybus/dockinggateway',
                },
                {
                    icon: 'fall',
                    title: '落地网关',
                    path: '/gatewaybus/falllandgateway',
                }
            ]
        }, {
            icon: "underline",
            title: '线路管理',
            path: '/usermanagement/routeinformation',
        },
        {
            icon: 'home',
            title: '码号管理',
            children: [{
                    icon: 'menu',
                    title: '号码管理',
                    path: '/number/Anumget',
                },
                {
                    icon: 'menu',
                    title: '号码分配',
                    path: '/number/Anum',
                },
            ]
        }, {
            icon: 'wallet',
            title: '设备管理',
            path: '/devicemanagement',
        }
    ]
}, {
    icon: 'eye',
    title: '监控',
    children: [{
        icon: 'user',
        title: '操作日志',
        path: '/monitora',
    }]
}, {
    title: '设置',
    icon: 'setting',
    children: [{
            icon: 'team',
            path: '/role',
            title: '系统角色',
        },
        {
            icon: 'user-add',
            title: '系统用户',
            path: '/createuser',
        },
        {
            icon: 'mobile',
            title: '手机地区',
            path: '/mobilephonearea',
        },
        {
            path: '/home',
            icon: 'compass',
            title: '修改密码',
        },
        {
            icon: 'number',
            title: '系统参数',
            path: '/systemparams',
        },
        {
            icon: 'snippets',
            title: '业务类型',
            path: '/businesstype',
        },
        {
            icon: 'phone',
            title: '呼速管理',
            path: '/callspeed',
        },
        {
            icon: 'sound',
            title: '告警设置',
            children: [{
                    icon: 'alert',
                    path: '/alarm/day',
                    title: '日消费阀值告警',
                },
                {
                    icon: 'alert',
                    path: '/alarm/line',
                    title: '线路最低阀值告警',
                },
                {
                    icon: 'alert',
                    path: '/alarm/account',
                    title: '企业及线路最低消费告警',
                },
            ]
        }
    ]
}, {
    title: '查询',
    icon: 'search',
}]
/**
 * @author 孟繁波
 * @param path      跳转路由地址
 * @param pid       上级路由名称
 * @param auth      是否验证登陆
 * @param title     当前导航标题
 * @param component 当前路由对应组件
 * @description     2020-12-05 路由映射集合
 */
const routes = [{
        path: "/",
        pid: '首页',
        auth: true,
        exact: true,
        title: "首页",
        component: HomePage,
    },
    {
        auth: true,
        pid: '登陆',
        title: "登陆",
        exact: true,
        path: "/login",
        component: Login,
    },
    {
        auth: true,
        pid: '设置',
        title: "系统用户",
        icon: "user-add",
        path: "/createuser",
        component: CreateUser,
    },
    {
        auth: true,
        pid: '设置',
        icon: "team",
        path: "/role",
        ptitle: '设置',
        component: Role,
        title: "系统角色",
    },
    {
        auth: true,
        pid: '设置',
        icon: "mobile",
        title: "手机地区",
        path: "/mobilephonearea",
        component: MobilePhoneArea,
    },
    {
        auth: true,
        pid: '设置',
        path: "/home",
        icon: "compass",
        component: Home,
        title: "修改密码",
    },
    {
        auth: true,
        pid: '业务',
        icon: "api",
        title: "对接网关",
        component: DockingGate,
        path: "/gatewaybus/dockinggateway",
    },
    {
        auth: true,
        pid: '业务',
        icon: "fall",
        title: "落地网关",
        component: FallGate,
        path: "/gatewaybus/falllandgateway",
    },
    {
        auth: true,
        pid: '业务',
        icon: "menu",
        title: "号码管理",
        component: NumberA,
        path: "/number/Anumget",
    },
    {
        auth: true,
        pid: '业务',
        icon: "menu",
        title: "号码分配",
        component: NumberA1,
        path: "/number/Anum",
    },
    {
        auth: true,
        pid: '业务',
        icon: "wallet",
        title: "设备管理",
        path: "/devicemanagement",
        component: DeviceManagement,
    },
    {
        auth: true,
        pid: '业务',
        icon: 'underline',
        title: '线路管理',
        component: RouteInformation,
        path: "/usermanagement/routeinformation"
    },
    {
        auth: true,
        pid: '账户',
        title: "企业账户",
        icon: "account-book",
        component: Enterprise,
        path: "/usermanagement/enterprise",
    },
    {
        auth: true,
        pid: '账户',
        icon: "fall",
        title: "结算账户",
        component: Fallland,
        path: "/usermanagement/fallland",
    },
    {
        auth: true,
        pid: '监控',
        icon: "user",
        title: "操作日志",
        path: "/monitora",
        component: MonitorA,
    },
    {
        auth: true,
        pid: '设置',
        icon: "number",
        title: "系统参数",
        path: "/systemparams",
        component: SystemParams,
    },
    {
        auth: true,
        pid: '设置',
        icon: "number",
        title: "业务类型",
        path: "/businesstype",
        component: BusinessType,
    },
    {
        auth: true,
        pid: '设置',
        icon: "number",
        title: "呼速管理",
        path: "/callspeed",
        component: CallSpeed,
    }, {
        auth: true,
        pid: '报表',
        title: "线路码号分配",
        icon: "user-add",
        path: "/reportallpage/linecodenum",
        component: LineCodeNum,
    }, {
        auth: true,
        pid: '报表',
        title: "码号全国分配",
        icon: "user-add",
        path: "/reportallpage/codenumdistributionchina",
        component: CodeNumDistributionChina,
    }, {
        auth: true,
        pid: '报表',
        title: "码号新旧详情",
        icon: "user-add",
        path: "/reportallpage/codenumoldnew",
        component: CodeNumOldNew,
    }, {
        auth: true,
        pid: '报表',
        title: "Bottom10地区",
        icon: "user-add",
        path: "/reportallpage/bottom10region",
        component: Bottom10Region,
    }, {
        auth: true,
        pid: '报表',
        title: "TOP10地区",
        icon: "user-add",
        path: "/reportallpage/top10region",
        component: Top10Region,
    }, {
        auth: true,
        pid: '报表',
        title: "botton20用户",
        icon: "user-add",
        path: "/reportallpage/botton20user",
        component: Botton20User,
    }, {
        auth: true,
        pid: '报表',
        title: "top20用户",
        icon: "user-add",
        path: "/reportallpage/top20user",
        component: Top20User,
    }, {
        auth: true,
        pid: '报表',
        title: "码号分配详情",
        icon: "user-add",
        path: "/reportallpage/codenumassignment",
        component: CodeNumAssignment,
    },
    {
        auth: true,
        pid: '设置',
        component: Day,
        path: '/alarm/day',
        title: "日消费阀值告警",
    },
    {
        auth: true,
        pid: '设置',
        title: '告警信息',
        component: Message,
        path: '/alarm/message',
    },
    {
        auth: true,
        pid: '设置',
        component: Line,
        path: '/alarm/line',
        title: '线路最低阀值告警',
    },
    {
        auth: true,
        pid: '设置',
        component: Account,
        path: '/alarm/account',
        title: '企业及线路最低消费告警',
    },
    {
        title: "404",
        path: "/404",
        component: Error,
    }
];

export {
    menu,
    routes,
}