import { handleActions } from "redux-actions";
import {
	fallgateTypes,
	dockgateTypes,
	gategroupTypes,
	FallGateAddTypes,
	DockAddTypes,
	DockGroupAddTypes,
	AddFallTypes,
	HidNumGateTypes,
	AddFidListTypes,
	FidAddListTypes,
	addcusTypes,
	AddHidListTypes,
	SeeListTypes,dockrecycleTypes,fallgaterecycleTypes,gatewayrecycleTypes,hidgaterecycleTypes
} from "../../actions/gatewaybus/gatewayTypes";

const defaultState = {
	fallgatelist: [],
	pages: "",
	falltotal:"",
	fallgateaddlist: [],

	dockgatelist: [],
	pages: "",
	docktotal:"",
	// dockgateaddlist:[],
	// dockgategroupaddlist:[],
	cusaddList: [],

	gategrouplist: [],
	pages: "",
	gatetotal:"",
	addfalllist: [],

	hidnumlist: [],
	pages: "",
	hidtotal:"",

	hidaddlist: [],
	hidnumgateaddList: [],
	hidgateaddList: [],

	hidseeList: [],

	dockrecycleList:[],
	dockrecycletotal:"",
	
	fallgaterecycleList:[],
	fallgaterecycletotal:"",
	
	gatewayrecycleList:[],
	gatewayrecycletotal:"",
	
	hidgaterecycleList:[],
    hidgaterecycletotal:"",
};

export default handleActions(
	{
		[fallgateTypes]:(state, action) => {
			// console.log(action,7777777)
			let fallgateState = JSON.parse(JSON.stringify(state));
			fallgateState.fallgatelist = action.payload.data;
			fallgateState.pages = action.payload.pages;
			fallgateState.falltotal=action.payload.total;
			return fallgateState;
		},
		[dockgateTypes]:(state, action) => {
			// console.log(action,7777777)
			let dockgateState = JSON.parse(JSON.stringify(state));
			dockgateState.dockgatelist = action.payload.data;
			dockgateState.pages = action.payload.pages;
			dockgateState.docktotal=action.payload.total;
			return dockgateState;
		},
		[gategroupTypes]:(state, action) => {
			// console.log(action,7777777)
			let gategroupState = JSON.parse(JSON.stringify(state));
			gategroupState.gategrouplist = action.payload.data;
			gategroupState.pages = action.payload.pages;
			gategroupState.gatetotal=action.payload.total;
			return gategroupState;
		},
		[FallGateAddTypes]:(state, action) => {
			// console.log(action,7777777)
			let FallGateAddState = JSON.parse(JSON.stringify(state));
			FallGateAddState.fallgateaddlist = action.payload.data;
			return FallGateAddState;
		},
		[AddFallTypes]:(state, action) => {
			// console.log(action,7777777)
			let AddfALLState = JSON.parse(JSON.stringify(state));
			AddfALLState.addfalllist = action.payload.data;
			return AddfALLState;
		},
		[HidNumGateTypes]:(state, action) => {
			// console.log(action,7777777)
			let hidGateState = JSON.parse(JSON.stringify(state));
			hidGateState.hidnumlist = action.payload.data;
			hidGateState.pages = action.payload.pages;
			hidGateState.hidtotal=action.payload.total;
			return hidGateState;
		},
		[AddFidListTypes]:(state, action) => {
			// console.log(action,7777777)
			let fidListState = JSON.parse(JSON.stringify(state));
			fidListState.hidaddlist = action.payload.data;
			return fidListState;
		},
		[FidAddListTypes]:(state, action) => {
			// console.log(action,7777777)
			let fidAddListState = JSON.parse(JSON.stringify(state));
			fidAddListState.hidgateaddList = action.payload.data;
			return fidAddListState;
		},
		[addcusTypes]:(state, action) => {
			// console.log(action,7777777)
			let addcusListState = JSON.parse(JSON.stringify(state));
			addcusListState.cusaddList = action.payload.data;
			return addcusListState;
		},
		[AddHidListTypes]:(state, action) => {
			// console.log(action,7777777)
			let addhidgateListState = JSON.parse(JSON.stringify(state));
			addhidgateListState.hidnumgateaddList = action.payload.data;
			return addhidgateListState;
		},
		[SeeListTypes]:(state, action) => {
			// console.log(action,7777777)
			let SeeListState = JSON.parse(JSON.stringify(state));
			SeeListState.hidseeList = action.payload.data;
			// console.log(SeeListState.hidseeList, 99999999);
			return SeeListState;
		},
		[dockrecycleTypes]:(state, action) => {
			// console.log(action,7777777)
			let dockrecycleState=JSON.parse(JSON.stringify(state));
        	dockrecycleState.dockrecycleList=action.payload.data;
        	dockrecycleState.dockrecycletotal=action.payload.total;
        	return dockrecycleState
		},
		[fallgaterecycleTypes]:(state, action) => {
			// console.log(action,7777777)
			let fallgaterecycleState=JSON.parse(JSON.stringify(state));
        	fallgaterecycleState.fallgaterecycleList=action.payload.data;
        	fallgaterecycleState.fallgaterecycletotal=action.payload.total;
        	return fallgaterecycleState
		},
		[gatewayrecycleTypes]:(state, action) => {
			// console.log(action,7777777)
			let gatewayrecycleState=JSON.parse(JSON.stringify(state));
        	gatewayrecycleState.gatewayrecycleList=action.payload.data;
        	gatewayrecycleState.gatewayrecycletotal=action.payload.total;
        	return gatewayrecycleState
		},
		[hidgaterecycleTypes]:(state, action) => {
			// console.log(action,7777777)
			let hidgaterecycleState=JSON.parse(JSON.stringify(state));
        	hidgaterecycleState.hidgaterecycleList=action.payload.data;
        	hidgaterecycleState.hidgaterecycletotal=action.payload.total;
        	return hidgaterecycleState
		},
	},
	defaultState
);
