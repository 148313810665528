import Loadable from "react-loadable"
import Loading from "common/loading";

export const Home = Loadable({
    loader: _ => import("./home"),
    loading: Loading
})

export const Firststep = Loadable({
    loader: _ => import("./configure/firststep"),
    loading: Loading
})

export const Login = Loadable({
    loader: _ => import("./login"),
    loading: Loading
})

export const UserInfo = Loadable({
    loader: _ => import("./users/userInfo"),
    loading: Loading
})

export const CodeNumOldNew = Loadable({
    loader: _ => import("./reportallpage/codenumoldnew"),
    loading: Loading
})

export const Role = Loadable({
    loader: _ => import("./role"),
    loading: Loading
})

export const CreateUser = Loadable({
    loader: _ => import("./createuser"),
    loading: Loading
})

export const Rate = Loadable({
    loader: _ => import("./rate"),
    loading: Loading
})

export const Enterprise = Loadable({
    loader: _ => import("./usermanagement/enterprise"),
    loading: Loading
})

export const Fallland = Loadable({
    loader: _ => import("./usermanagement/fallland"),
    loading: Loading
})

export const Hidnum = Loadable({
    loader: _ => import("./usermanagement/hidnum"),
    loading: Loading
})

export const DockingGate = Loadable({
    loader: _ => import("./gatewaybus/dockinggateway"),
    loading: Loading
})

export const FallGate = Loadable({
    loader: _ => import("./gatewaybus/falllandgateway"),
    loading: Loading
})

export const HidNumGateWay = Loadable({
    loader: _ => import("./gatewaybus/hidnumgateway"),
    loading: Loading
})

export const GateGroup = Loadable({
    loader: _ => import("./gatewaybus/gatewaygroup"),
    loading: Loading
})
export const NumberA = Loadable({
    loader: _ => import("./number/Anumget"),
    loading: Loading
})

export const NumberA1 = Loadable({
    loader: _ => import("./number/ACnum"),
    loading: Loading
})

export const NumberAX = Loadable({
    loader: _ => import("./number/Axnumget"),
    loading: Loading
})

export const NumberAX1 = Loadable({
    loader: _ => import("./number/Axnum"),
    loading: Loading
})

export const HuaDan = Loadable({
    loader: _ => import("./huadan"),
    loading: Loading
})
export const HomePage = Loadable({
    loader: _ => import("./homepage"),
    loading: Loading
})

export const Safe = Loadable({
    loader: _ => import("./safe"),
    loading: Loading
})

export const MonitorA = Loadable({
    loader: _ => import("./monitoras/monitora"),
    loading: Loading
})

export const BookList1 = Loadable({
    loader: _ => import("./monitoras/Backlist"),
    loading: Loading
})

export const PersonList1 = Loadable({
    loader: _ => import("./monitoras/personList"),
    loading: Loading
})

export const MaintainA = Loadable({
    loader: _ => import("./maintain"),
    loading: Loading
})

export const BookList = Loadable({
    loader: _ => import("./bookList"),
    loading: Loading
})

export const Accountlevel = Loadable({
    loader: _ => import("./Accountlevel"),
    loading: Loading
})

export const NumberTest = Loadable({
    loader: _ => import("./numberTest"),
    loading: Loading
})
export const RechargeMana = Loadable({
    loader: _ => import("./rechargemanagement"),
    loading: Loading
})

export const Bottom10Region = Loadable({
    loader: _ => import("./reportallpage/bottom10region"),
    loading: Loading
})

export const Top10Region = Loadable({
    loader: _ => import("./reportallpage/top10region"),
    loading: Loading
})

export const LineCodeNum = Loadable({
    loader: _ => import("./reportallpage/linecodenum"),
    loading: Loading
})

export const CodeNumDistributionChina = Loadable({
    loader: _ => import("./reportallpage/codenumdistributionchina"),
    loading: Loading
})

export const Botton20User = Loadable({
    loader: _ => import("./reportallpage/botton20user"),
    loading: Loading
})

export const CodeNumAssignment = Loadable({
    loader: _ => import("./reportallpage/codenumassignment"),
    loading: Loading
})

export const Top20User = Loadable({
    loader: _ => import("./reportallpage/top20user"),
    loading: Loading
})

export const HidAccountCallsta = Loadable({
    loader: _ => import("./reportallpage/hidaccountcallsta"),
    loading: Loading
})

export const Xmlpage = Loadable({
    loader: _ => import("./Xml"),
    loading: Loading
})

export const Customerpage = Loadable({
    loader: _ => import("./CustomerCansu"),
    loading: Loading
})

export const Callpage = Loadable({
    loader: _ => import("./CallPage"),
    loading: Loading
})

// 告警设置（系统告警页面）
export const SystemAlarm = Loadable({
    loader: _ => import("./alarmsetting/systemalarm"),
    loading: Loading
})
// 告警设置（对接网关告警页面）
export const DockGateAlarm = Loadable({
    loader: _ => import("./alarmsetting/dockgatealarm"),
    loading: Loading
})
// 告警设置（落地网关告警页面）
export const FallGateAlarm = Loadable({
    loader: _ => import("./alarmsetting/fallgatealarm"),
    loading: Loading
})
// 业务 号码管理中的号码群组
export const NumberGroupWhite = Loadable({
    loader: _ => import("./number/numbergroupwhite"),
    loading: Loading
})
// 查询 实时话单获取
export const Realtimebill = Loadable({
    loader: _ => import("./realtimebill"),
    loading: Loading
})
// 呼数管理
export const CallInManage = Loadable({
    loader: _ => import("./callspeed"),
    loading: Loading
})
// 设置下面新增页面 手机地区
export const MobilePhoneArea = Loadable({
    loader: _ => import("./mobilephonearea"),
    loading: Loading
})
// 监控下面新增页面 历史并发
export const HistoryConcurr = Loadable({
    loader: _ => import("./historyconcurr"),
    loading: Loading
})
// 业务下面 全局变量
export const GlobalVariable = Loadable({
    loader: _ => import("./globalvariable"),
    loading: Loading
})
// 业务下面设备管理
export const DeviceManagement = Loadable({
    loader: _ => import("./devicemanagement"),
    loading: Loading
})
// 业务下面设备的使用历史
export const DeviceUsageHistory = Loadable({
    loader: _ => import("./deviceusagehistory"),
    loading: Loading
})
// 业务下面码号管理
export const CodeNumberManage = Loadable({
    loader: _ => import("./codenumbermanage"),
    loading: Loading
})
// 账户下面线路信息
export const RouteInformation = Loadable({
    loader: _ => import("./usermanagement/routeinformation"),
    loading: Loading
})
// 系统参数
export const SystemParams = Loadable({
    loader: _ => import('./systemParams'),
    loading: Loading
})
// 业务类型
export const BusinessType = Loadable({
    loader: _ => import('./businessType'),
    loading: Loading
})
// 呼速管理
export const CallSpeed=Loadable({
    loader: _ => import('./callspeed'),
    loading: Loading
});
// 呼速管理
export const Error=Loadable({
    loader: _ => import('./error'),
    loading:Loading
})
// 告警日消费阀值
export const Day=Loadable({
    loader: _ => import('./alarm/day'),
    loading:Loading
});
// 获取已读信息及未读信息
export const Message=Loadable({
    loader: _ => import('./alarm/message'),
    loading:Loading
});
// 线路最低码号阀值告警
export const Line=Loadable({
    loader: _ => import('./alarm/line'),
    loading:Loading
});
// 企业及线路最低消费告警信息
export const Account=Loadable({
    loader: _ => import('./alarm/account'),
    loading:Loading
});