import React, { Component } from 'react';
import { Container } from "./styled";

class Loading extends Component {
	render() {
		return (
			<Container>
				<div className="loader">
					<div className="loader-inner">
						<div className="loader-line-wrap">
							<div className="loader-line"></div>
						</div>
						<div className="loader-line-wrap">
							<div className="loader-line"></div>
						</div>
						<div className="loader-line-wrap">
							<div className="loader-line"></div>
						</div>
						<div className="loader-line-wrap">
							<div className="loader-line"></div>
						</div>
						<div className="loader-line-wrap">
							<div className="loader-line"></div>
						</div>
					</div>
				</div>
			</Container>
		)
	}
}

export default Loading