export const fallgateTypes="FALL_GATE_TYPES";

export const dockgateTypes="DOCK_GATE_TYPES";

export const gategroupTypes="GATE_GROUP_TYPES";

export const FallGateAddTypes="FALL_GATE_ADD_TYPES";

export const DockAddTypes="DOCK_ADD_TYPES";

export const DockGroupAddTypes="DOCK_GROUP_ADD_TYPES";

export const AddFallTypes="ADD_FALL_TYPES";

export const HidNumGateTypes="HID_NUM_GATE_TYPES";

export const AddFidListTypes="ADD_FID_LIST_TYPES";

export const FidAddListTypes="FID_ADD_LIST_TYPES";

export const addcusTypes="ADD_CUS_TYPES";

export const AddHidListTypes="ADD_HID_LIST_TYPES";

export const SeeListTypes="SEE_LIST_TYPES";

export const dockrecycleTypes="DOCK_RECYCLE_TYPES";

export const fallgaterecycleTypes="FALL_GATE_RECYCLE_TYPES";

export const gatewayrecycleTypes="GATEWAY_RECYCLE_TYPES";

export const hidgaterecycleTypes="HIDGATE_RECYCLE_TYPES";