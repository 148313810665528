// qs.stringify()将对象序列化成url的形式，以&进行拼接
import qs from "qs";
import Cookies from "js-cookie";
// fetch是对xhrhttprequest请求的封装
import { fetch } from "whatwg-fetch";
import { Modal, message } from 'antd';
let $alert = false;
let status = [200, 300];

const fetchPro = (url, options) => {
    return fetch(url, Object.assign({ keepalive: true, }, options)).then(async res => {
        if (status.includes(res.status)) {
            let data = await res.json();
            if (data) {
                if (data.code === 200) return data;
                if (data.code === 1012) {
                    if ($alert) return false;
                    $alert = true;
                    let $modal = Modal.info({
                        okText: '确定',
                        content: `${data.msg}`,
                        onOk() {
                            $modal.destroy();
                            $alert = false;
                            window.location.href = '#/login';
                        }
                    });
                    return false;
                }
                Modal.warning({
                    content: `${data.msg}`
                });
            }

        } else {
            // 通知报错单个信息
            // if ($alert) return false;
            // $alert = true;


            // 通知报错所有信息
            message.error(`接口---[${res.url}]请求异常---状态码[${res.status}]---错误信息[${res.statusText}]`, 3, () => {
                // $alert = false;
            });
        }
        return false;
    });




}
const get = (options) => {
    // console.log(options.data)
    let url = options.url;
    let data = options.data;
    let supplement = options.supplement;
    if (supplement) {
        var str1 = "";
        for (var key1 in supplement) {
            str1 += "&" + supplement[key1];
        }
        url = url + str1.slice(1)
    }


    if (data) {
        var str = "";
        for (var key in data) {
            str += "&" + key + "=" + data[key];
        }

        url = url + "?" + str.slice(1);
    }
    var result = fetchPro(url, {
        // body:qs.stringify(options.body),
        method: 'GET',
        headers: {
            "content-type": "application/json",
            ...options.headers
        }
    })

    return result;
}



const post = (options) => {
    let csrf = Cookies.get("csrftoken");
    let supplement = options.supplement;
    if (supplement) {
        var str = "";
        for (var key in supplement) {
            str += "&" + supplement[key];
        }
        options.url = options.url + str.slice(1)
    }
    var result = fetchPro(options.url, {
        method: 'POST',
        body: qs.stringify(options.data),
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "X-CSRFToken": csrf,
            ...options.headers
        }
    })
    return result;
}


const patch = (options) => {
    let csrf = Cookies.get("csrftoken");
    let psupplement = options.psupplement;
    if (psupplement) {
        var strp = "";
        for (var keyp in psupplement) {
            strp += "&" + psupplement[keyp];
        }
        options.url = options.url + strp.slice(1)
    }
    var result = fetchPro(options.url, {
        method: 'PATCH',
        body: qs.stringify(options.data),
        headers: {
            keepalive: false,
            "content-type": "x-www-form-urlencoded",
            "X-CSRFToken": csrf,
            ...options.headers
        }
    })
    return result;
}

const put = (options) => {
    // console.log(options,123111)
    let csrf = Cookies.get("csrftoken");
    let hsupplement = options.hsupplement;
    if (hsupplement) {
        var strh = "";
        for (var keyh in hsupplement) {
            strh += "&" + hsupplement[keyh];
        }
        options.url = options.url + strh.slice(1)
    }
    var result = fetchPro(options.url, {
        method: 'PUT',
        body: qs.stringify(options.data),
        headers: {
            "content-type": "x-www-form-urlencoded",
            "X-CSRFToken": csrf,
            ...options.headers
        }
    })
    return result;
}
const DELETE = (options) => {
    // console.log(options.body)
    let csrf = Cookies.get("csrftoken");
    let url = options.url;
    let data = options.data;
    if (data) {
        var str = "";
        for (var key in data) {
            str += "&" + data[key];
        }
        url = url + str.slice(1);
    }
    var result = fetchPro(url, {
        // body:qs.stringify(options.body),
        method: options.method || 'delete',
        body: qs.stringify(options.bodydata),
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "X-CSRFToken": csrf,
            ...options.headers
        }
    })

    return result;
}
export default {
    get,
    post,
    patch,
    put,
    DELETE
}
window.$http = {
    get,
    post,
    patch,
    put,
    DELETE
}