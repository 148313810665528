
import React from 'react';
import { routes } from './map';
import { Error } from '../pages/index';
import { Switch, Route, Redirect, } from "react-router-dom";
/**
 * @param route  当前路由
 * @description  2020-12-05 公共路由跳转逻辑
 */
const PublicRoute = (route) => {
    window.$route = route;
    let isAuthenticated = sessionStorage.getItem('user');
    if (!isAuthenticated || !sessionStorage.getItem('routeDate')) return <Redirect to={{ pathname: '/login' }} />
    return (
        <Route path={route.path} exact={route.exact} render={props => (<route.component  {...props} routes={route.routes} />)} />
    );
}

// 根路由渲染
const Router = (props) => {
    return (
        <div {...props}>
            <Switch >
                <Route path='/404' component={Error} />
                {routes.map((route, i) => {
                    if (route.title === '登陆') return <Route key={i} path={route.path} exact={route.exact} component={route.component} />
                    if (route.title === '404') { window.$route = route; return <Route key={i} path='*' exact={route.exact} render={()=><Redirect to={{ pathname: '/404' }} />}  />}
                    // 根据路由配置判断检测登陆
                    return <PublicRoute key={i}  {...route} />
                })}
            </Switch>
        </div>

    )
}

export {
    Router
}