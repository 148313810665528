import {handleActions} from "redux-actions";
import {customerTypes,cusModifyTypes,fallLandTypes,fallModifyTypes,cusAddTypes,fallAddTypes,hidnumTypes,hidModifyTypes,hidAddTypes,pipelineTypes,querypageTypes,recycleTypes,fallrecycleTypes,hidrecycleTypes,raterecycleTypes} from "../../actions/customerAction/customerTypes";

const defaultState={
    customerList:[],
    pages:"",
    total:"",
    cusModifyList:[],
    cusAddList:[],

    fallLandList:[],
    fallpages:"",
    falltotal:"",
    fallModifyList:[],
    fallAddList:[],

    hidenumList:[],
    hidenumpages:'',
    hidtotal:"",
    hidMidList:[],
    hidAddList:[],


    pipelineList:[],
    pages:"",
    total:"",


    _querypages:"",
    _querytotal:"",


    recycleList:[],
    recyclypage:"",
    recycletotal:"",

    fallrecycleList:[],
    falrecycletotal:"",

    hidrecycleList:[],
    hidrecycletotal:"",

    raterecycleList:[],
    raterecycletotal:"",
}

export default handleActions({
    [customerTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let customerState=JSON.parse(JSON.stringify(state));
        customerState.customerList=action.payload.data;
        customerState.pages=action.payload.pages;
        customerState.total=action.payload.total;
        return customerState
    },
    [cusModifyTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let cusModifyState=JSON.parse(JSON.stringify(state));
        cusModifyState.cusModifyList=action.payload.data;
        return cusModifyState
    },
    [fallLandTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let fallLandState=JSON.parse(JSON.stringify(state));
        fallLandState.fallLandList=action.payload.data;
        fallLandState.fallpages=action.payload.pages;
        fallLandState.falltotal=action.payload.total;
        return fallLandState
    } ,
    [fallModifyTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let fallModifyState=JSON.parse(JSON.stringify(state));
        fallModifyState.fallModifyList=action.payload.data;
        return fallModifyState
    },
    [cusAddTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let cusAddState=JSON.parse(JSON.stringify(state));
        cusAddState.cusAddList=action.payload.data;
        return cusAddState
    },
    [fallAddTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let fallAddState=JSON.parse(JSON.stringify(state));
        fallAddState.fallAddList=action.payload.data;
        return fallAddState
    },
    [hidnumTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let hidenumState=JSON.parse(JSON.stringify(state));
        hidenumState.hidenumList=action.payload.data;
        hidenumState.hidenumpages=action.payload.pages;
        hidenumState.hidtotal=action.payload.total;
        return hidenumState
    },
    [hidModifyTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let hidModifyState=JSON.parse(JSON.stringify(state));
        hidModifyState.hidMidList=action.payload.data;
        return hidModifyState
    },
    [hidAddTypes]:(state,action)=>{
        // console.log(state,action,55555)
        let hidAddState=JSON.parse(JSON.stringify(state));
        hidAddState.hidAddList=action.payload.data;
        return hidAddState
    },
    [pipelineTypes]:(state,action)=>{
        // console.log(action,55555)
        let pipelineState=JSON.parse(JSON.stringify(state));
        pipelineState.pipelineList=action.payload.data;
        pipelineState.pages=action.payload.pages;
        pipelineState.total=action.payload.total;
        return pipelineState
    },
    [querypageTypes]:(state,action)=>{
        // console.log(action,55555)
        let querypagesState=JSON.parse(JSON.stringify(state));
        querypagesState._querypages=action.payload;
        return querypagesState
    },
    [recycleTypes]:(state,action)=>{
        // console.log(action,55555)
        let recycleState=JSON.parse(JSON.stringify(state));
        recycleState.recycleList=action.payload.data;
        recycleState.recyclypage=action.payload.pages;
        recycleState.recycletotal=action.payload.total;
        return recycleState
    },
    [fallrecycleTypes]:(state,action)=>{
        // console.log(action,55555)
        let fallrecycleState=JSON.parse(JSON.stringify(state));
        fallrecycleState.fallrecycleList=action.payload.data;
        fallrecycleState.falrecycletotal=action.payload.total;
        return fallrecycleState
    },
    [hidrecycleTypes]:(state,action)=>{
        // console.log(action,55555)
        let hidrecycleState=JSON.parse(JSON.stringify(state));
        hidrecycleState.hidrecycleList=action.payload.data;
        hidrecycleState.hidrecycletotal=action.payload.total;
        return hidrecycleState
    },
    [raterecycleTypes]:(state,action)=>{
        // console.log(action,55555)
        let raterecycleState=JSON.parse(JSON.stringify(state));
        raterecycleState.raterecycleList=action.payload.data;
        raterecycleState.raterecycletotal=action.payload.total;
        return raterecycleState
    },

},defaultState)