import styled from "styled-components";
export const Layout = styled.div`
        .Anumbox{
            width: 100%;
            height: 816px;
        }
        .AumTop{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom:10px;
        }
        .leftTop{
            width: 445px;
            height: 100%;
           button{
               margin-right:20px;
           }
        }
        .rightTop{
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        
        .recording_content {
            margin-top:10px;
            display: flex;
            max-height:300px;
            overflow:auto;
            flex-direction:column;
            span{
              color:red;
            }
            
        }
        .read>.ant-btn{
            width:20%;
            margin-bottom:20px;
        }
        .read>audio{
            border:none;
            outline:none;
            &:focus{
                border:none;
                outline:none;
            }
            width:100%;
        }
      .bill-item{
            cursor:pointer;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-variant: tabular-nums;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";
            position: relative;
            display: inline-block;
            width: 100%;
            height: 32px;
            padding: 4px 11px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            line-height: 1.5;
            background-color: #fff;
            background-image: none;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            transition: all 0.3s;
        }
        
    `
