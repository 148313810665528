import './App.min.css';
import React, { Component } from 'react'
import { Switch } from "react-router-dom";
// import routeEach from "utils/routeEach"
import Layout from "router";
// import {RLayoutRoutes} from "router/reportform.js";
// import Layout from "layout"
class App extends Component {
  render() {
    return (
      <Switch>
        <Layout />
      </Switch>
    )
  }
}

export default App
