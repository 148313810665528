import {handleActions} from "redux-actions";
import {firstlevelTypes,secondlevelTypes,messagedetailsTypes,RouteFirstClassTypes,routemessageinforTypes} from "../../actions/newaccount/newaccountTypes";
import {FallLandrouteTypes} from "../../actions/customerAction/customerTypes"

const defaultState={
    firstlevellist:"",

    secondlevelllist:"",

    messagedetailsList:"",
    messagedetailstotal:"",

    FallLandrouteList:[],
    FallLandroutetotal:"",

    RouteFirstClassList:"",

    routemessageinforList:[],
    routemessageinfortotal:""
}

export default handleActions({
    [firstlevelTypes]:(state,action)=>{
        // 结算账户中一级信息的获取
        // console.log(action.payload.data,999999)
        let firstlevelState=JSON.parse(JSON.stringify(state));
        firstlevelState.firstlevellist=action.payload.data;
        return firstlevelState
    },
    [secondlevelTypes]:(state,action)=>{
        // 结算账户中二级信息的获取
        // console.log(action.payload.data,999999)
        let secondlevelState=JSON.parse(JSON.stringify(state));
        secondlevelState.secondlevelllist=action.payload.data;
        return secondlevelState
    },
    [messagedetailsTypes]:(state,action)=>{
        // 结算账户中详情信息的获取
        // console.log(state,action,5555555)
        let messagedetailsState=JSON.parse(JSON.stringify(state));
        messagedetailsState.messagedetailsList=action.payload.data;
        messagedetailsState.messagedetailstotal=action.payload.total;
        return messagedetailsState
    },
    [FallLandrouteTypes]:(state,action)=>{
        // 线路信息的获取数据（结算账户）
        // console.log(state,action,5555555)
        let FallLandrouteState=JSON.parse(JSON.stringify(state));
        FallLandrouteState.FallLandrouteList=action.payload.data;
        FallLandrouteState.FallLandroutetotal=action.payload.total;
        return FallLandrouteState
    },
    [RouteFirstClassTypes]:(state,action)=>{
        // 线路信息的获取一级信息数据（结算账户）
        // console.log(state,action,5555555)
        let RouteFirstClassState=JSON.parse(JSON.stringify(state));
        RouteFirstClassState.RouteFirstClassList=action.payload.data;
        return RouteFirstClassState
    },
    [routemessageinforTypes]:(state,action)=>{
        // 线路信息的获取线路的详情数据（结算账户）
        // console.log(state,action,5555555)
        let routemessageinforState=JSON.parse(JSON.stringify(state));
        routemessageinforState.routemessageinforList=action.payload.data;
        routemessageinforState.routemessageinfortotal=action.payload.total;
        return routemessageinforState
    }
},defaultState)