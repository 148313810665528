import {handleActions} from "redux-actions";
import {monitorTypes,codenumoldnewTypes,bottom10regionTypes,top10regionTypes,linecodenumTypes,codenumchinaTypes,botton20userTypes,codenumassignmentTypes,top20userTypes,hidacccallstaTypes} from "../../actions/monitorA/monitoraTypes";

const defaultState = {
    monitorList:[],
    pages:"",
    total:"",
    show:"",

    codenumoldnewList:[],
    codenumoldnewtotal:"",

    bottom10regionList:[],
    bottom10regiontotal:"",

    top10regionList:[],
    top10regiontotal:"",

    linecodenumList:[],
    linecodenumtotal:"",

    codenumchinaList:[],
    codenumchinatotal:"",

    botton20userList:[],
    botton20usertotal:"",

    codenumassignmentList:[],
    codenumassignmenttotal:"",

    top20userList:[],
    top20usertotal:"",

    hidacccallstaList:[],
    hidacccallstatotal:""
}


export default handleActions({
    [monitorTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let monitorState=JSON.parse(JSON.stringify(state));
        monitorState.monitorList=action.payload.data;
        monitorState.pages=action.payload.pages;
        monitorState.total=action.payload.total;
        monitorState.show=action.payload.show;
        return monitorState
    },
    [codenumoldnewTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        // 码号新旧详情的页面
        let codenumoldnewState=JSON.parse(JSON.stringify(state));
        codenumoldnewState.codenumoldnewList=action.payload.data;
        codenumoldnewState.codenumoldnewtotal=action.payload.total;
        return codenumoldnewState
    },
    [bottom10regionTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let bottom10regionState=JSON.parse(JSON.stringify(state));
        bottom10regionState.bottom10regionList=action.payload.data;
        bottom10regionState.bottom10regiontotal=action.payload.total;
        return bottom10regionState
    },
    [top10regionTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let top10regionState=JSON.parse(JSON.stringify(state));
        top10regionState.top10regionList=action.payload.data;
        top10regionState.top10regiontotal=action.payload.total;
        return top10regionState
    },
    [linecodenumTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let anumdatestaState=JSON.parse(JSON.stringify(state));
        anumdatestaState.linecodenumList=action.payload.data;
        anumdatestaState.linecodenumtotal=action.payload.total;
        return anumdatestaState
    },
    [codenumchinaTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let codenumchinaState=JSON.parse(JSON.stringify(state));
        codenumchinaState.codenumchinaList=action.payload.data;
        codenumchinaState.codenumchinatotal=action.payload.total;
        return codenumchinaState
    },
    [botton20userTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let botton20userState=JSON.parse(JSON.stringify(state));
        botton20userState.botton20userList=action.payload.data;
        botton20userState.botton20usertotal=action.payload.total;
        return botton20userState
    },
    [codenumassignmentTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let codenumassignmentState=JSON.parse(JSON.stringify(state));
        codenumassignmentState.codenumassignmentList=action.payload.data;
        codenumassignmentState.codenumassignmenttotal=action.payload.total;
        return codenumassignmentState
    },
    [top20userTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let top20userState=JSON.parse(JSON.stringify(state));
        top20userState.top20userList=action.payload.data;
        top20userState.top20usertotal=action.payload.total;
        return top20userState
    },
    [hidacccallstaTypes]:(state,action)=>{
        // console.log(state,action,5555555)
        let hidacccallstaState=JSON.parse(JSON.stringify(state));
        hidacccallstaState.hidacccallstaList=action.payload.data;
        hidacccallstaState.hidacccallstatotal=action.payload.total;
        return hidacccallstaState
    },
},defaultState)