const http = window.$http;
// 获取信息
export const getMessage = data => http.get({
    data,
    url: 'ajax/v2.0/get_daily_message/'
});
// 删除告警设置信息
export const delConfigInfo = bodydata => http.DELETE({
    bodydata,
    url: 'ajax/v2.0/set_daily_consumption/'
});
// 新增告警设置信息
export const createConfigInfo = data => http.post({
    data,
    url: 'ajax/v2.0/set_daily_consumption/'
});
// 获取角色
export const getRoles = data => http.get({
    data,
    url: 'ajax/v2.0/get_role_name/'
});
// 获取企业账号
export const getAccount = data => http.get({
    data,
    url: 'ajax/v2.0/account_fuzzy_query/'
});
// 确认信息提交
export const submit = data => http.post({
    data,
    url: 'ajax/v2.0/get_daily_message/'
});