export const monitorTypes="MONITOR_TYPES";

export const codenumoldnewTypes="CODE_NUM_OLD_NEW_TYPES";

export const bottom10regionTypes="BOTTOM_10_REGION_TYPES";

export const top10regionTypes="TOP_10_REGION_TYPES";

export const linecodenumTypes="LINE_CODE_NUM_TYPES";

export const codenumchinaTypes="CODE_NUM_CHINA_TYPES";

export const botton20userTypes="BOTTON_20_USER_TYPES";

export const codenumassignmentTypes="CODE_NUM_ASSIGNMENT_TYPES";

export const top20userTypes="TOP_20_USER_TYPES";

export const hidacccallstaTypes="HID_ACC_CALL_STA_TYPES";