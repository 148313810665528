export const FallAlarmTypes="FALL_ALARM_TYPES";

export const DockAlarmTypes="DOCK_ALARM_TYPES";

export const SystemAlarmTypes="SYSTEM_ALARM_TYPES";

export const AccgetidTypes="ACC_GET_ID_TYPES";

export const RealtimebillTypes="REAL_TIME_BILL_TYPES";

export const CallInManageTypes="CALL_IN_MANAGE_TYPES";

export const MobileAreaTypes="MOBILE_AREA_TYPES";

export const HistoryConTypes="HISTORY_CON_TYPES";

export const GlobalvariTypes="GLOBAL_VARI_TYPES";

export const DeviceManTypes="DEVICE_MAN_TYPES";

export const DeviceUsageHisTypes="DEVICE_USAGE_HIS_TYPES";

export const CodeNumManTypes="CODE_NUM_MAN_TYPES";